import type { FC } from 'react'
import { memo } from 'react'

import { posthog } from '@/utils'

const currentYear = new Date().getFullYear()

const Footer: FC = () => (
	<footer className="mt-auto py-4 md:py-6 relative border-t border-white/5 bg-black w-full">
		<div className="container mx-auto px-4 md:px-6 max-w-7xl">
			<div className="flex flex-col md:flex-row items-center justify-between">
				<div className="mb-6 md:mb-0 text-center md:text-left">
					<p className="text-white/50 text-sm md:text-base">
						©{currentYear} SMotors. All rights reserved
					</p>
				</div>

				<div className="flex flex-col md:flex-row items-center gap-8">
					<div className="flex items-center gap-4">
						<a
							className="w-10 h-10 rounded-full bg-white/5 hover:bg-red-500/20 flex items-center justify-center transition-colors duration-300"
							href="https://www.facebook.com/SMotorsPL/"
							onClick={() => {
								posthog?.capture('Clicked Social Link', {
									platform: 'Facebook',
									url: 'https://www.facebook.com/SMotorsPL/',
								})
							}}
							rel="noopener noreferrer"
							target="_blank"
						>
							<svg
								className="text-white"
								fill="none"
								height="20"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>Facebook</title>
								<path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
							</svg>
						</a>
						<a
							className="w-10 h-10 rounded-full bg-white/5 hover:bg-red-500/20 flex items-center justify-center transition-colors duration-300"
							href="https://www.instagram.com/smotorspl"
							onClick={() => {
								posthog?.capture('Clicked Social Link', {
									platform: 'Instagram',
									url: 'https://www.instagram.com/smotorspl',
								})
							}}
							rel="noopener noreferrer"
							target="_blank"
						>
							<svg
								className="text-white"
								fill="none"
								height="20"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>Instagram</title>
								<rect height="20" rx="5" ry="5" width="20" x="2" y="2" />
								<path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
								<line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
							</svg>
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
)

export default memo(Footer)
