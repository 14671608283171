import type { TranslationPath, TranslationResource } from '@/utils/i18n'
import { useTranslation } from 'react-i18next'

/**
 * A wrapper around useTranslation that provides type-safe translations
 * using the actual structure of the translation files.
 */
export const useTypedTranslation = () => {
	// Call useTranslation hook - this will always return the same shape object
	const { t, i18n } = useTranslation()

	// Create a simple wrapper function that doesn't need memoization
	// Since we're not using hooks inside it, it's safe
	const typedT = (key: TranslationPath, options?: Record<string, unknown>) =>
		t(key, options)

	// Helper function that doesn't need memoization
	const getAllTranslationKeys = (): TranslationResource =>
		i18n.getResourceBundle(i18n.language, 'translation') as TranslationResource

	return {
		t: typedT,
		i18n,
		getAllTranslationKeys,
	}
}
