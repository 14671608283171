import {
	motion,
	useAnimationFrame,
	useMotionTemplate,
	useMotionValue,
	useTransform,
} from 'framer-motion'
import type React from 'react'
import {
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { cn } from '@/utils'

interface MovingBorderProps {
	children: React.ReactNode
	duration?: number
	rx?: string
	ry?: string
	[key: string]: unknown
}

const MovingBorder: React.FC<MovingBorderProps> = ({
	children,
	duration = 4000,
	rx,
	ry,
	...otherProps
}) => {
	const pathRef = useRef<SVGRectElement | null>(null)
	const progress = useMotionValue<number>(0)
	const [pathLength, setPathLength] = useState<number>(0)
	const [pathInitialized, setPathInitialized] = useState<boolean>(false)

	// Measure the SVG path as soon as it's rendered.
	useLayoutEffect(() => {
		if (!pathRef.current) return

		const initializePath = () => {
			const element = pathRef.current
			if (!element) return
			try {
				const length = element.getTotalLength()
				setPathLength(length || 1000)
			} catch {
				const rect = element.getBoundingClientRect()
				const approximateLength = 2 * (rect.width + rect.height)
				setPathLength(approximateLength)
			}
			setPathInitialized(true)
		}

		// Use requestAnimationFrame for a precise timing of initialization.
		const rafId = requestAnimationFrame(initializePath)
		return () => cancelAnimationFrame(rafId)
	}, [])

	const calculatePosition = useCallback(
		(val: number) => {
			if (!pathRef.current) return { x: 0, y: 0 }
			const rect = pathRef.current.getBoundingClientRect()
			const circumference = 2 * (rect.width + rect.height)
			const position = (val / pathLength) * circumference

			if (position < rect.width) return { x: position, y: 0 }
			if (position < rect.width + rect.height)
				return { x: rect.width, y: position - rect.width }
			if (position < 2 * rect.width + rect.height)
				return {
					x: rect.width - (position - (rect.width + rect.height)),
					y: rect.height,
				}
			return {
				x: 0,
				y: rect.height - (position - (2 * rect.width + rect.height)),
			}
		},
		[pathLength],
	)

	// Precompute the speed (pixels per millisecond) once pathLength or duration changes.
	const pxPerMillisecond = useMemo(
		() => pathLength / duration,
		[pathLength, duration],
	)

	useAnimationFrame((time) => {
		if (pathInitialized && pathLength > 0) {
			progress.set((time * pxPerMillisecond) % pathLength)
		}
	})

	const getPointAtLength = useCallback(
		(val: number) => {
			if (!(pathInitialized && pathRef.current)) return { x: 0, y: 0 }
			try {
				const point = pathRef.current.getPointAtLength(val)
				return point
			} catch {
				return calculatePosition(val)
			}
		},
		[pathInitialized, calculatePosition],
	)

	const x = useTransform(progress, (val) => getPointAtLength(val).x)
	const y = useTransform(progress, (val) => getPointAtLength(val).y)
	const transform = useMotionTemplate`translateX(${x}px) translateY(${y}px) translateX(-50%) translateY(-50%)`

	return (
		<>
			<svg
				className="absolute h-full w-full"
				height="100%"
				preserveAspectRatio="none"
				width="100%"
				xmlns="http://www.w3.org/2000/svg"
				{...otherProps}
			>
				<title>Moving Border</title>
				<rect
					fill="none"
					height="100%"
					ref={pathRef}
					rx={rx}
					ry={ry}
					stroke="rgba(0,0,0,0.001)"
					strokeWidth="1"
					width="100%"
				/>
			</svg>
			<motion.div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					display: 'inline-block',
					transform,
				}}
			>
				{children}
			</motion.div>
		</>
	)
}

const MemoizedMovingBorder = memo(MovingBorder)

interface MovingBorderButtonProps {
	borderRadius?: string
	children: React.ReactNode
	as?: 'button' | 'a'
	containerClassName?: string
	borderClassName?: string
	duration?: number
	className?: string
	[key: string]: unknown
}

const MovingBorderButton: React.FC<MovingBorderButtonProps> = ({
	borderRadius = '1.75rem',
	children,
	as: Component = 'button',
	containerClassName,
	borderClassName,
	duration,
	className,
	...otherProps
}) => {
	// Memoize style objects to avoid unnecessary re-renders.
	const containerStyle = useMemo(() => ({ borderRadius }), [borderRadius])
	const innerStyle = useMemo(
		() => ({ borderRadius: `calc(${borderRadius} * 0.96)` }),
		[borderRadius],
	)

	return (
		<Component
			className={cn(
				'bg-transparent relative text-xl h-16 w-40 p-[1px] overflow-hidden',
				containerClassName,
			)}
			style={containerStyle}
			{...otherProps}
		>
			<div className="absolute inset-0" style={innerStyle}>
				<MemoizedMovingBorder duration={duration} rx="30%" ry="30%">
					<div
						className={cn(
							'h-10 w-10 opacity-[0.8] bg-[radial-gradient(var(--red-500)_40%,transparent_60%)]',
							borderClassName,
						)}
					/>
				</MemoizedMovingBorder>
			</div>
			<div
				className={cn(
					'relative bg-black border border-red-500/20 backdrop-blur-xl text-white flex items-center justify-center w-full h-full text-sm antialiased',
					className,
				)}
				style={innerStyle}
			>
				{children}
			</div>
		</Component>
	)
}

export default memo(MovingBorderButton)
