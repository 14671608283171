import { AnimatePresence, motion } from 'framer-motion'
import { Globe } from 'lucide-react'
import type React from 'react'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useTypedTranslation } from '@/hooks'
import { cn, posthog } from '@/utils'

interface LanguageSwitcherProps {
	className?: string
}

// A custom hook to check media query matches
const useMediaQuery = (query: string): boolean => {
	const [matches, setMatches] = useState<boolean>(() =>
		typeof window !== 'undefined' ? window.matchMedia(query).matches : false,
	)

	useEffect(() => {
		const mediaQueryList = window.matchMedia(query)
		const listener = (event: MediaQueryListEvent) => {
			setMatches(event.matches)
		}
		mediaQueryList.addEventListener('change', listener)
		return () => {
			mediaQueryList.removeEventListener('change', listener)
		}
	}, [query])

	return matches
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ className }) => {
	const { i18n } = useTypedTranslation()
	const currentLanguage = i18n.language
	const [isOpen, setIsOpen] = useState(false)
	const dropdownRef = useRef<HTMLDivElement>(null)
	// Use custom hook to detect mobile view
	const isMobile = useMediaQuery('(max-width: 767px)')

	// Memoize languages to prevent unnecessary recalculations
	const languages = useMemo(
		() => Object.keys(i18n.options?.resources || {}),
		[i18n.options?.resources],
	)

	// Handle click outside to close the dropdown
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				setIsOpen(false)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	// Memoize switchLanguage to prevent unnecessary re-renders
	const switchLanguage = useCallback(
		(lang: string) => {
			// Track language change
			posthog?.capture('Changed Language', {
				fromLanguage: currentLanguage,
				toLanguage: lang,
				viewportType: isMobile ? 'mobile' : 'desktop',
			})

			i18n.changeLanguage(lang)
			setIsOpen(false)
		},
		[i18n, currentLanguage, isMobile],
	)

	// Compute dropdown positioning and sizing based on viewport
	const dropdownPosition = isMobile ? 'left-0' : 'right-0'
	const minDropdownWidth = isMobile ? 'min-w-[80px]' : 'min-w-[70px]'
	const maxDropdownHeight = isMobile ? 'max-h-[180px]' : 'max-h-[200px]'

	return (
		<div className={cn('relative', className)} ref={dropdownRef}>
			<button
				aria-label="Toggle language menu"
				className="relative flex items-center gap-2 px-2 py-1 rounded-md transition-colors duration-200 hover:text-white text-white/80 group"
				onClick={() => setIsOpen((prev) => !prev)}
				type="button"
			>
				<motion.div
					animate={{ rotate: isOpen ? 360 : 0 }}
					transition={{ duration: 0.4, ease: 'easeInOut' }}
				>
					<Globe
						className="text-red-500 group-hover:text-red-400 transition-colors duration-300"
						size={15}
					/>
				</motion.div>
				<span className="text-sm font-medium uppercase group-hover:text-white transition-colors">
					{currentLanguage}
				</span>
				<motion.div
					animate={{ rotate: isOpen ? 180 : 0 }}
					className="ml-1 opacity-70 group-hover:opacity-100 transition-opacity"
					transition={{ duration: 0.3 }}
				>
					<svg
						aria-hidden="true"
						className="text-white/70"
						fill="none"
						height="6"
						viewBox="0 0 10 6"
						width="10"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 1L5 5L9 1"
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.5"
						/>
					</svg>
				</motion.div>
			</button>

			<AnimatePresence>
				{isOpen && (
					<motion.div
						animate={{ opacity: 1, y: 0, scale: 1 }}
						className={cn(
							'absolute',
							dropdownPosition,
							'top-full mt-2',
							minDropdownWidth,
							'bg-black/90 backdrop-blur-md rounded-lg shadow-lg border border-white/10 z-50 overflow-hidden',
						)}
						exit={{ opacity: 0, y: -5, scale: 0.95 }}
						initial={{ opacity: 0, y: -5, scale: 0.95 }}
						transition={{ duration: 0.2, ease: 'easeOut' }}
					>
						<div
							className={cn('overflow-y-auto py-1', maxDropdownHeight)}
							style={{
								scrollbarWidth: 'thin',
								scrollbarColor: '#ef4444 #1f2937',
								WebkitOverflowScrolling: 'touch',
							}}
						>
							{languages.map((lang) => (
								<motion.button
									className={cn(
										'w-full text-left px-3 py-2 text-sm rounded-md flex items-center gap-2 transition-all duration-200',
										currentLanguage === lang
											? 'bg-red-500/20 text-white border-l-2 border-red-500 pl-2.5'
											: 'text-white/70 hover:text-white hover:bg-white/10 border-l-2 border-transparent',
									)}
									key={lang}
									onClick={() => switchLanguage(lang)}
									type="button"
								>
									<span className="uppercase font-medium tracking-wide">
										{lang}
									</span>
								</motion.button>
							))}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

export default memo(LanguageSwitcher)
