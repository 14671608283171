import { motion } from 'framer-motion'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

const Error404: FC = () => (
	<div className="min-h-screen flex items-center justify-center bg-black text-white p-4">
		<div className="noise-bg absolute inset-0 pointer-events-none" />
		<motion.div
			animate={{ opacity: 1, y: 0 }}
			className="max-w-md w-full text-center relative z-10"
			initial={{ opacity: 0, y: 20 }}
			transition={{ duration: 0.5 }}
		>
			<h1 className="text-8xl font-bold text-red-500 mb-4">404</h1>
			<h2 className="text-2xl mb-6">Page Not Found</h2>
			<p className="mb-8 text-gray-400">
				The page you are looking for doesn't exist or has been moved.
			</p>
			<Link
				className="inline-flex items-center justify-center px-6 py-3 border border-red-500 text-red-500 rounded-full hover:bg-red-500/10 transition-colors"
				to="/"
			>
				Return Home
			</Link>
		</motion.div>
	</div>
)

export default Error404
